.aboutMe {
    color: white;
    font-family: 'Barlow', sans-serif;
}

#profilePic {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
}

#aboutMeGraphics {
    position: absolute;
    z-index: -1;
    top: -3em;
    left: -11em;
}

.boldPinkText {
    color: #DB62B8;
    font-weight: 700;
}

.boldBlueText {
    color: #99E9FC;
    font-weight: 700;
}

.boldYellowText {
    color: #FBF44C;
    font-weight: 700;
}

#skiing {
    height: 60%;
    width: 68vw;
    margin-left: 15vw;
    margin-top: 5em;
}

.aboutMe h4 {
    font-size: 1.8vw;
    font-weight: 500;
    line-height: 143.4%;
    margin-left: 16vw;
    margin-bottom: 10vh;
    width: 70vw;
}

#skiingText {
    margin-top: -2em;
}

#dancing {
    height: 60%;
    width: 68vw;
    margin-left: 15vw;
    margin-top: 5vh;
}

#foodie {
    height: 60%;
    width: 68vw;
    margin-left: 15vw;
    margin-top: 5vh;
}

#foodieText {
    margin-bottom: 20vh;
}

#daliLabLink {
    color: white;
    font-style: italic;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
    box-shadow: inset 0 0 0 0 white;
}

#daliLabLink:hover {
    box-shadow: inset 100px 0 0 0 white;
    color: black;
    text-decoration: none;
}