.Navbar {
    font-family: 'Raleway', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1.5vh;
    z-index: 1000;
    position: relative;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2vw;
}

/* Logo */
.logoPic {
    /* position: absolute;
    left: 3vw; */
}

/* "Justin Chong" */
#homeStyle {
    font-family: 'Alliance No. 2 Bold';
    font-size: 2vw;
    font-weight: 800;
    text-decoration: none;
    background-image: linear-gradient(to right, #7FAADB, #B0E4BC, #F7DE6D, #F08339);
    background-clip: text;
    color: transparent;
}

#caseStyle {
    font-family: 'Alliance No. 2 Bold';
    font-size: 2vw;
    font-weight: 800;
    text-decoration: none;
    background-color: #000;
    background-clip: text;
    color: transparent;
}


/* Group of Nav Buttons */
.navButtons {
    display: flex;
    align-items: center;
    list-style: none;
    column-gap: 2vw;
}

/* Nav Button */
.navButtons > li {
    /* margin: 0vh 1vw; */
}

.navButtons a, #email-me-button, #email-me-button-case, #resume-download-button, #resume-download-button-case {
    text-decoration: none;
    font-family: 'IBM Plex Mono', sans-serif;
    letter-spacing: -0.03em;
    font-size: 1vw;
    font-weight: 800;
    background-color: #F5F5F5;
    color: black;
    border: 1.5px solid black;
    border-radius: 7px;
    padding: 1.3vh 2vw;
    width: 10vw;
}

#about-me-button {
    box-shadow: 3px 3px #99e9fc;
}

#about-me-button-case {
    box-shadow: 3px 3px #000;
}

#about-me-button:hover {
    box-shadow: 3px 3px white;
    background-color: #99e9fc;
}

#about-me-button-case:hover {
    background-color: #99e9fc;
}


#email-me-button {
    box-shadow: 3px 3px #99e9fc;
}

#email-me-button-case {
    box-shadow: 3px 3px #000;
}

#email-me-button:hover {
    cursor: pointer;
    box-shadow: 3px 3px white;
    background-color: #99e9fc;
}

#email-me-button-case:hover {
    cursor: pointer;
    background-color: #99e9fc;
}


#resume-download-button {
    box-shadow: 3px 3px #99e9fc;
}

#resume-download-button-case {
    box-shadow: 3px 3px #000;
}

#resume-download-button:hover {
    cursor: pointer;
    box-shadow: 3px 3px white;
    background-color: #99e9fc;
}

#resume-download-button-case:hover {
    background-color: #99e9fc;
}


/* #dropdownItemSWE {
    background-color: #160C59;
    color: white;
    font-weight: 600;
    display: flex;
    border-radius: 7px 7px 0px 0px;
    border-bottom: 1px solid #160C59;
}

#dropdownItemDesign {
    background-color: #160C59;
    color: white;
    font-weight: 600;
    display: flex;
    border-radius: 0px;
    border-top: 1px solid #160C59;
    border-bottom: 1px solid #160C59;
}

#dropdownItemPM {
    background-color: #160C59;
    color: white;
    font-weight: 600;
    display: flex;
    border-radius: 0px 0px 7px 7px;
    border-top: 1px solid #160C59;
}

#dropdownItemSWE:hover {
    background-color: gray;
}

#dropdownItemDesign:hover {
    background-color: gray;
}

#dropdownItemPM:hover {
    background-color: gray;
} */