.container {
    background-color: #fbfbfd;
    font-family: 'Barlow', sans-serif;
}

.cover {
    height: 100vh;
    background-image: linear-gradient(to bottom right, #A0E5DC , #D2E5A6); 
    color: #ffffff;
    position: relative;
}

#paCoverBottom {
    position: absolute;
    bottom: -1px;
    z-index: 0;
}

.coverContent {
    display: flex;
    justify-content: space-around;
    margin-top: 10vh;
    margin-left: 5vw;
    margin-right: 5vw;
}

.coverInfo {
    width: 40%;
}

.cover h1 {
    font-weight: 700;
    font-size: 3.5vw;
    background-image: linear-gradient(to right, #CBF07B , #C5DDB9); 
    background-clip: text;
    color: transparent;
}

.cover h2 {
    font-weight: 500;
    font-size: 1.8vw;
    line-height: 1.5em;
}

.cover h3 {
    font-weight: 400;
    font-size: 1.5vw;
    display: inline;
    padding: 1vh 1.5vw;
    line-height: 6vh;
    border: 1px solid #ffffff;
    border-radius: 50px;
}

.background {
    background-color: #E6F1DD;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 3vh;
    margin-top: 8vh;
    padding-top: 8vh;
    padding-bottom: 8vh;
}

.background h5 {
    font-family: 'Alliance No. 2 Bold';
    color: #7BB151;
    font-size: 1.6em;
    text-align: center;
    line-height: 1.4em;
    width: 70vw;
    margin-top: 0;
    margin-bottom: 0;
}

.background h6 {
    color: #88C15B;
    font-family: 'Alliance No. 2 Regular';
    font-size: 1.2em;
    text-align: center;
    line-height: 1.4em;
    width: 60vw;
    margin-top: 0;
    margin-bottom: 0;
}

#process-container {
    display: flex;
    justify-content: center;
}

#paStats {
    margin-top: -1vh;
}

#paProcessMockup {
    display: flex;
    align-self: center;
    margin-top: 8vh;
}

#paResults {
    margin-top: 5vh;
}

#paTakeaways {
    margin-top: 5vh;
}