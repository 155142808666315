.ohBuoyCaseStudy {
    background-color: #fbfbfd;
    font-family: 'Barlow', sans-serif;
}


.ohBuoyCover {
    height: 100vh;
    background-color: #000000;
    color: #ffffff;
}

.ohBuoyCover h1 {
    font-weight: 700;
    font-size: 4.7vw;
    margin-top: 16vh;
    margin-left: 13vw;
    margin-bottom: 3vh;
    width: 33.5%;
    background-image: linear-gradient(to right, #FEF452 , #F4AB3F); 
    background-clip: text;
    color: transparent;
}

.ohBuoyCover h2 {
    font-weight: 500;
    font-size: 1.8vw;
    width: 25%;
    line-height: 1.5em;
    margin-left: 13vw;
    margin-top: 7vh;
}

.ohBuoyCover h3 {
    font-weight: 400;
    font-size: 1.5vw;
    display: inline;
    padding: 1vh 1.5vw;
    line-height: 6vh;
    margin-left: 13vw;
    border: 1px solid #ffffff;
    border-radius: 50px;
}

/* ----- Oh Buoy Intro ---------------------------*/

.ohBuoyIntro {
    height: 100vh;
}

#ohBuoyIntroQuestion {
    color: #D9991D;
    margin-top: 0;
    margin-left: 10vw;
    padding-top: 5vh;
    width: 60vw;
    font-weight: 800;
}

#ohBuoyIntroText {
    color: #1D1D1F;
    margin-left: 10vw;
    line-height: 1.4em;
    width: 60vw;
    font-weight: 500;
}

/* ----- Text and Other Modifications ---------------------------*/

#orangeCaseSideHeader {
    color: #D9991D;
}

#ohBuoyOrangeBackground {
    background-color: #FFEDC9;
    margin-bottom: 0;
    padding-top: 3em;
    padding-bottom: 3em;
}

#ohBuoyStats {
    position: relative;
    top: -16.5em;
    left: 70em;
}

#ohBuoyOverview {
    margin-top: 2em;
}