.fitkitchCaseStudy {
    background-color: #fbfbfd;
    font-family: 'Barlow', sans-serif;
}

#fitkitchCover {
    background-image: linear-gradient(to bottom right, #FFB1B1 , #FF89C0); 
}

.fitkitchCover h1 {
    font-family: 'Barlow';
    color: #ffffff;
    font-weight: 700;
    font-size: 5vw;
    margin-top: 15vh;
    margin-left: 13vw;
    margin-bottom: 1vh;
    width: 18%;
    background-image: linear-gradient(to right, #7B80F0 , #B9C7DD); 
    background-clip: text;
    color: transparent;
}

.fitkitchCover h2 {
    color: #ffffff;
    font-weight: 500;
    font-size: 1.8vw;
    width: 30vw;
    line-height: 1.4em;
    letter-spacing: -0.01em;
    margin-left: 13vw;
    margin-top: 7vh;
}

.fitkitchCover h3 {
    color: #ffffff;
    font-weight: 400;
    font-size: 1.5vw;
    display: inline;
    padding: 1vh 1.5vw;
    line-height: 6vh;
    margin-left: 13vw;
    border: 1px solid #ffffff;
    border-radius: 50px;
}

.fitkitchCover img {
    position: absolute;
    margin-left: 45em;
    margin-top: -21em;
}

/* -----FitKitch Intro ---------------------------*/

#fitkitchIntroQuestion {
    color: #E96060;
    margin-top: 0;
    margin-left: 10vw;
    padding-top: 5vh;
    width: 60vw;
    font-weight: 800;
}

#fitkitchIntroText {
    color: #1D1D1F;
    margin-left: 10vw;
    line-height: 1.4em;
    width: 60vw;
    font-weight: 500;
}

/* ----- Text and Other Modification ---------------------------- */

#redCaseSideHeader {
    color: #E96060;
}

.fitkitchBoldText {
    font-weight: 700;
}

#fitkitchRedBackground {
    background-color: #FFCFD8;
    margin-bottom: 0;
    padding-top: 3em;
    padding-bottom: 3em;
}

#fitkitchSolution {
    background-color: #fecfd8;
    padding-top: 15vh;
    padding-bottom: 15vh;
    margin-bottom: 10vh;
}

#fitkitchSolution1 {
    color: #ea6060;
    margin-left: 15vw;
    margin-bottom: 0.3em;
    font-size: 1.5em;
    font-weight: 700;
    width: 20vw;
}

#fitkitchSolution2 {
    color: #EC1B1B;
    margin-left: 15vw;
    margin-top: 0;
    margin-bottom: 0.3em;
    font-size: 4em;
    font-weight: 800;
    width: 20vw;
}

#fitkitchSolution3{
    color: #EC1B1B;
    margin-left: 15vw;
    font-weight: 500;
    width: 60vw;
    line-height: 1.5em;
    position: relative;
}

#fitkitchSolutionHeading {
    position: absolute;
    margin-left: 65em;
    margin-top: -13.7em;
}


#fitkitchBestMockups {
    /* position: relative;
    top: -19.2em;
    left: -3em; */
}

#fitkitchDesignThinkingGradient {
    background-image: linear-gradient(to right, #7F00FF, #E100FF);
    background-clip: text;
    color: transparent;
}

#fitkitchUserNeedsGradient {
    background-image: linear-gradient(to right, #0B8FFA, #E5DB3E);
    background-clip: text;
    color: transparent;
}

#fitkitchSketchesGradient {
    background-image: linear-gradient(to right, #008860, #15C55B);
    background-clip: text;
    color: transparent;
}

#fitkitchAccessibilityGradient {
    background-image: linear-gradient(to right, #FF028B, #ECC023);
    background-clip: text;
    color: transparent;
}