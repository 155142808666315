html, body {
  max-width: 100%;
  overflow-x: hidden;
}

.Intro {
  color: #F5F5F5;
  font-family: 'Alliance No. 2 Bold', sans-serif;
  margin: 4vh 10.7vw;
}

.Intro > h1 {
  font-size: 5vw;
  font-weight: 800;
  margin: 2vh 0vw;
  display: inline;
}

/* Description */
.Intro > h2 {
  font-size: 2.5vw;
  font-weight: 700;
  width: 60%;
  letter-spacing: 0.2px;
}

#linkedinLogo {
  float: left;
}

#githubLogo {
  float: left;
  margin-left: 1.5%;
}

.Intro > h3 {
  font-weight: 400;
  font-size: 1.3vw;
  margin: -0.15vh 0.7vw;
  float: left;
}

h3 > a {
  color: #F5F5F5;
  text-decoration: none;
  font-family: 'IBM Plex Mono';
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
  box-shadow: inset 0 0 0 0 white;
}

.Intro a:hover {
  box-shadow: inset 100px 0 0 0 white;
  color: black;
}

.pinkText {
  color: #DB62B8;
}

.blueText {
  color: #99E9FC;
}

#abstractShapes {
  position: absolute;
  top: -40px;
  right: -150px;
  z-index: -1;
}

body {
  background-color: black;
}

@font-face {
  font-family: "Alliance No. 2 Black";   /*Can be any text*/
  src: local("AllianceNo2-Black"),
    url("./fonts/AllianceNo2-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Alliance No. 2 Black Italic";   /*Can be any text*/
  src: local("AllianceNo2-BlackItalic"),
    url("./fonts/AllianceNo2-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Alliance No. 2 Bold";   /*Can be any text*/
  src: local("AllianceNo2-Bold"),
    url("./fonts/AllianceNo2-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Alliance No. 2 Bold Italic";   /*Can be any text*/
  src: local("AllianceNo2-BoldItalic"),
    url("./fonts/AllianceNo2-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Alliance No. 2 Extra Bold";   /*Can be any text*/
  src: local("AllianceNo2-ExtraBold"),
    url("./fonts/AllianceNo2-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Alliance No. 2 Extra Bold Italic";   /*Can be any text*/
  src: local("AllianceNo2-ExtraBoldItalic"),
    url("./fonts/AllianceNo2-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Alliance No. 2 Light";   /*Can be any text*/
  src: local("AllianceNo2-Light"),
    url("./fonts/AllianceNo2-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Alliance No. 2 Light Italic";   /*Can be any text*/
  src: local("AllianceNo2-LightItalic"),
    url("./fonts/AllianceNo2-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Alliance No. 2 Medium";   /*Can be any text*/
  src: local("AllianceNo2-Medium"),
    url("./fonts/AllianceNo2-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Alliance No. 2 Medium Italic";   /*Can be any text*/
  src: local("AllianceNo2-MediumItalic"),
    url("./fonts/AllianceNo2-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Alliance No. 2 Regular";   /*Can be any text*/
  src: local("AllianceNo2-Regular"),
    url("./fonts/AllianceNo2-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Alliance No. 2 Regular Italic";   /*Can be any text*/
  src: local("AllianceNo2-RegularItalic"),
    url("./fonts/AllianceNo2-RegularItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Alliance No. 2 Semi Bold";   /*Can be any text*/
  src: local("AllianceNo2-SemiBold"),
    url("./fonts/AllianceNo2-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Alliance No. 2 Semi Bold Italic";   /*Can be any text*/
  src: local("AllianceNo2-SemiBoldItalic"),
    url("./fonts/AllianceNo2-SemiBoldItalic.ttf") format("truetype");
}