.conferenceCupUSACaseStudy {
    background-color: #fbfbfd;
    font-family: 'Barlow', sans-serif;
}


.conferenceCupUSACover {
    height: 100vh;
    background-color: #000000;
    color: #ffffff;
}

.conferenceCupUSACover h1 {
    font-weight: 700;
    font-size: 4.2vw;
    margin-top: 16vh;
    margin-left: 13vw;
    margin-bottom: 3vh;
    width: 42.2%;
    background-image: linear-gradient(to right, #CBF07B , #C5DDB9); 
    background-clip: text;
    color: transparent;
}

.conferenceCupUSACover h2 {
    font-weight: 500;
    font-size: 1.8vw;
    width: 25%;
    line-height: 1.5em;
    margin-left: 13vw;
    margin-top: 7vh;
}

.conferenceCupUSACover h3 {
    font-weight: 400;
    font-size: 1.5vw;
    display: inline;
    padding: 1vh 1.5vw;
    line-height: 6vh;
    margin-left: 13vw;
    border: 1px solid #ffffff;
    border-radius: 50px;
}

.conferenceCupUSACover img {
    position: absolute;
    margin-left: 52em;
    margin-top: -21em;
}

/* ----- ConferenceCup USA Intro ---------------------------*/

.conferenceCupUSAIntro {
    height: 100vh;
}

#conferenceCupUSAIntroQuestion {
    color: #88C15B;
    margin-top: 0;
    margin-left: 10vw;
    padding-top: 5vh;
    width: 60vw;
    font-weight: 800;
}

#conferenceCupUSAIntroText {
    color: #1D1D1F;
    margin-left: 10vw;
    line-height: 1.4em;
    width: 60vw;
    font-weight: 500;
}

#conferenceCupUSAStats {
    position: relative;
    top: -19em;
    left: 70em;
}

#conferenceCupUSABestMockups {
    position: relative;
    top: -20em;
    left: -6.6em;
}

#greenCaseSideHeader {
    color: #88C15B;
}

#conferenceCupUSAGreenBackground {
    background-color: #E6F1DD;
    margin-bottom: 0;
    padding-top: 3em;
    padding-bottom: 3em;
}