.chronicleCaseStudy {
    background-color: #fbfbfd;
    font-family: 'Barlow', sans-serif;
}

#chronicleCover {
    background-image: linear-gradient(to bottom right, #CFD6F8 , #C1F7CD);
}

.chronicleCover h1 {
    color: #008FFC;
    font-weight: 700;
    font-size: 5vw;
    margin-top: 15vh;
    margin-left: 13vw;
    margin-bottom: 1.5vh;
    width: 21%;
    background-image: linear-gradient(to right, #EC6D71 , #E55E9F); 
    background-clip: text;
    color: transparent;
}

.chronicleCover h2 {
    color: #ffffff;
    font-weight: 500;
    font-size: 1.8vw;
    width: 30vw;
    line-height: 1.4em;
    letter-spacing: -0.01em;
    margin-left: 13vw;
    margin-top: 8vh;
}

.chronicleCover h3 {
    color: #ffffff;
    font-weight: 400;
    font-size: 1.5vw;
    display: inline;
    padding: 1vh 1.5vw;
    line-height: 6vh;
    margin-left: 13vw;
    border: 1px solid #ffffff;
    border-radius: 50px;
}

.chronicleCover img {
    position: absolute;
    margin-left: 35em;
    margin-top: -30em;
}

/* -------- Chronicle Intro ---------------------------- */

.chronicleIntro {
    height: 100vh;
}

#chronicleIntroQuestion {
    color: #008FFC;
    margin-top: 0;
    margin-left: 10vw;
    padding-top: 5vh;
    width: 60vw;
    font-weight: 800;
}

#chronicleIntroText {
    color: #1D1D1F;
    margin-left: 10vw;
    line-height: 1.4em;
    width: 60vw;
    font-weight: 500;
}

/* -------- Chronicle Content ---------------------------- */

.caseSection {
    color: #1D1D1F; /* controls color of text for all case studies - light black */
    display: flex;
    align-items: flex-start;
    margin-top: 10vh;
}

.caseSideHeader {
    margin-left: 5vw;
    /* margin-top: 5vh; */
    width: 20vw;
}

.caseSideHeader > h3 {
    color: #008FFC;
    font-size: 1.2em;
    font-weight: 800;
    margin-bottom: 0;
    margin-top: 0;
}

.caseSideHeader > h2 {
    font-weight: 700;
    font-size: 1.8em;
    width: 15vw;
    line-height: 1.3em;
    margin-top: 0.4em;
    margin-bottom: 0;
}

.caseMain {
    /* margin-top: 5vh; */
}

.caseSectionHeader {
    font-size: 1.6em;
    font-weight: 800;
    margin-top: 0;
}

.caseText {
    font-weight: 500;
    font-size: 1.2em;
    width: 65vw;
    line-height: 1.4em;
}

#chronicleBestMockups {
    margin-top: 5vh;
}

/* ------ Text Decoration and Other Modification ----------------*/

.chronicleBoldText {
    font-weight: 700;
}

#chronicleUnderlineText {
    text-decoration: underline;
}

#backgroundText {
    margin-top: 0em;
    line-height: 1.6em;
}

#noSectionHeader {
    margin-top: 0em;
}

#chronicleBlueBackground {
    background-color: #D7EEFF;
    margin-bottom: 0;
    padding-top: 3em;
    padding-bottom: 3em;
}

#chronicleSolution {
    background-color: #D7EEFF;
    padding-top: 15vh;
    padding-bottom: 15vh;
    margin-bottom: 10vh;
}

#chronicleSolution1 {
    color: #008ffc;
    margin-left: 15vw;
    margin-bottom: 0.3em;
    font-size: 1.5em;
    font-weight: 700;
    width: 20vw;
}

#chronicleSolution2 {
    color: #005cfc;
    margin-left: 15vw;
    margin-top: 0;
    margin-bottom: 0.3em;
    font-size: 4em;
    font-weight: 800;
    width: 20vw;
}

#chronicleSolution3{
    color: #005cfc;
    margin-left: 15vw;
    font-weight: 500;
    width: 60vw;
    line-height: 1.5em;
}

#chronicleDecisions {
    margin-top: 7vh;
}

#chronicleTakeaways {
    margin-bottom: 5vh;
    margin-top: 3vh;
}

#chroniclePlant {
    position: absolute;
    margin-left: 62em;
    margin-top: -21em;
}

/* Resizes all headings in case studies according to screen size */
.caseStudyHeading {
    max-width: 100%;
    height: auto;
}

/* ------- Gradients for All Chronicle Text ----------------------*/

#designThinkingGradient {
    background-image: linear-gradient(to right, #E19446, #B78383);
    background-clip: text;
    color: transparent;
}

#generationalDivideGradient {
    background-image: linear-gradient(to right, #52A0B3, #7390E9, #BF73C7, #DB4D4D);
    background-clip: text;
    color: transparent;
}

#boomersGradient{
    background-image: linear-gradient(to right, #ee0979, #ff6a00);
    background-clip: text;
    color: transparent;
}

#genzGradient{
    background-image: linear-gradient(to right, #2F80ED, #56CCF2);
    background-clip: text;
    color: transparent;
}

#ethicsGradient {
    background-image: linear-gradient(to right, #7F00FF, #E100FF);
    background-clip: text;
    color: transparent;
}

#chronicleInterviewGradient {
    background-image: linear-gradient(to right, #26bbaa, #70d49b);
    background-clip: text;
    color: transparent;
}

#chronicleDisconnectGradient {
    background-image: linear-gradient(to right, #f12711, #f5af19);
    background-clip: text;
    color: transparent;
}

#chronicleDecisionsGradient {
    background-image: linear-gradient(to right, #11998e, #29c785);
    background-clip: text;
    color: transparent;
}

#chronicleUserTestingGradient {
    background-image: linear-gradient(to right, #5566df, #50a2be);
    background-clip: text;
    color: transparent;
}

#chronicleAccessibilityGradient {
    background-image: linear-gradient(to right, #3F5EFB, #FC466B);
    background-clip: text;
    color: transparent;
}
