.Footer {
    background-color: #0D1116;
    color: black;
    height: 25vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-top: 1px solid #42474E;
}

.Footer a {
    font-family: 'Alliance No. 2 Regular';
    text-decoration: none;
    color: #7E848E;
}

.Footer a:hover {
    color: #fff;
}

.Footer h4 {
    font-family: 'Alliance No. 2 Regular';
    font-weight: 500;
    font-size: 1.3vw;
    margin-top: 0px;
    color: #7E848E;
}

.Footer h4:hover {
    color: #fff;
    cursor: pointer;
}

.Footer h3 {
    font-family: 'Alliance No. 2 Extra Bold';
    font-size: 2vw;
    display: inline;
    color: #fff;
}

.subitems-1, .subitems-2 {
    display: flex;
    column-gap: 3vw;
    width: 30vw;
    font-family: 'Alliance No. 2 Regular';
}

.items-1, .items-2 {
    display: flex;
    flex-direction: column;
    row-gap: 0vh;
    width: 20vw;
}

#upScrollArrow:hover {
    cursor: pointer;
}