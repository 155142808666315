.ipathCaseStudy {
    background-color: #fbfbfd;
    font-family: 'Barlow', sans-serif;
}

.ipathCover {
    height: 100vh;
    color: #007875;
    background-color: #c9efee;
}

.ipathCover h1 {
    font-weight: 800;
    font-size: 5vw;
    margin-top: 15vh;
    margin-left: 13vw;
    margin-bottom: 1vh;
}

.ipathCover h2 {
    font-weight: 600;
    font-size: 2vw;
    width: 33vw;
    line-height: 6vh;
    margin-left: 13vw;
    margin-top: 7vh;
}

.ipathCover h3 {
    font-weight: 600;
    font-size: 1.5vw;
    display: inline;
    padding: 1vh 1.5vw;
    line-height: 6vh;
    margin-left: 13vw;
    border: 2px solid #007875;
    border-radius: 50px;
}

.ipathCover img {
    position: absolute;
    margin-left: 50vw;
    margin-top: -63vh;
}

/* -------------- iPath Intro ----------------------------- */
/* .ipathIntro {
    height: 100vh;
} */

#ipathIntroQuestion {
    color: #007875;
    margin-top: 0;
    margin-left: 10vw;
    padding-top: 5vh;
    width: 60vw;
    font-weight: 800;
}

#ipathIntroText {
    color: #1D1D1F;
    margin-left: 10vw;
    line-height: 1.4em;
    width: 60vw;
    font-weight: 500;
}

/* ----- Text and Other Modification ---------------------------- */
#ipathStats {
    position: relative;
    top: -20.5em;
    left: 70em;
}

#ipathGreenBackground {
    background-color: #c9efee;
    margin-bottom: 0;
    padding-top: 3em;
    padding-bottom: 3em;
}

#greenCaseSideHeader {
    color: #007875;
}