.budgetBalloonCaseStudy {
    background-color: #fbfbfd;
    font-family: 'Barlow', sans-serif;
}


.budgetBalloonCover {
    height: 100vh;
    background-color: #000000;
    color: #ffffff;
}

.budgetBalloonCover h1 {
    font-weight: 700;
    font-size: 4.7vw;
    margin-top: 16vh;
    margin-left: 13vw;
    margin-bottom: 3vh;
    width: 33.5%;
    background-image: linear-gradient(to right, #CBF07B , #C5DDB9); 
    background-clip: text;
    color: transparent;
}

.budgetBalloonCover h2 {
    font-weight: 500;
    font-size: 1.8vw;
    width: 25%;
    line-height: 1.5em;
    margin-left: 13vw;
    margin-top: 7vh;
}

.budgetBalloonCover h3 {
    font-weight: 400;
    font-size: 1.5vw;
    display: inline;
    padding: 1vh 1.5vw;
    line-height: 6vh;
    margin-left: 13vw;
    border: 1px solid #ffffff;
    border-radius: 50px;
}

.budgetBalloonCover img {
    position: absolute;
    margin-left: 49em;
    margin-top: -22em;
}

/* ----- Budget Balloon Intro ---------------------------*/

.budgetBalloonIntro {
    height: 100vh;
}

#budgetBalloonIntroQuestion {
    color: #F200AC;
    margin-top: 0;
    margin-left: 10vw;
    padding-top: 5vh;
    width: 60vw;
    font-weight: 800;
}

#budgetBalloonIntroText {
    color: #1D1D1F;
    margin-left: 10vw;
    line-height: 1.4em;
    width: 60vw;
    font-weight: 500;
}

/* ----- Text and Other Modifications ---------------------------*/

#pinkCaseSideHeader {
    color: #F200AC;
}

#budgetBalloonPinkBackground {
    background-color: #F6D7ED;
    margin-bottom: 0;
    padding-top: 3em;
    padding-bottom: 3em;
}

#budgetBalloonStats {
    position: relative;
    top: -12.7em;
    left: 70em;
}

#budgetBalloonSolution {
    background-color: #f6d7ed;
    padding-top: 15vh;
    padding-bottom: 15vh;
    margin-bottom: 10vh;
}

#budgetBalloonSolution1 {
    color: #EA33B4;
    margin-left: 15vw;
    margin-bottom: 0.3em;
    font-size: 1.5em;
    font-weight: 700;
    width: 20vw;
}

#budgetBalloonSolution2 {
    color: #E500A3;
    margin-left: 15vw;
    margin-top: 0;
    margin-bottom: 0.3em;
    font-size: 4em;
    font-weight: 800;
    width: 30vw;
}

#budgetBalloonSolution3{
    color: #E500A3;
    margin-left: 15vw;
    font-weight: 500;
    width: 60vw;
    line-height: 1.5em;
}

#budgetBalloonBalloon {
    position: absolute;
    margin-left: 65em;
    margin-top: -19em;
}

#budgetBalloonBestMockups {
    position: relative;
    top: -10.5em;
    left: -6.6em;
}

#budgetBalloonSolutionsGradient {
    background-image: linear-gradient(to right, #fc00ff, #00dbde);
    background-clip: text;
    color: transparent;
}